<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Code</label>
        <vs-input
          :disabled="true"
          class="w-full bg-grey-light rounded-lg"
          name="currency_code"
          v-model="data.currency_code"
        />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">From Currency</label>
        <multiselect
          v-if="editable"
          v-model="currency.from"
          :options="options"
          placeholder="Type to search"
          track-by="code"
          label="code"
          :max-height="125"
        >
        </multiselect>
        <vs-input v-else class="w-full bg-grey-light rounded-lg" v-model="currency.from.code" disabled></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('From Currency')"
        >{{ errors.first('From Currency') }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">To Currency</label>
        <multiselect
          v-if="editable"
          v-model="currency.to"
          :options="options"
          placeholder="Type to search"
          track-by="code"
          label="code"
          :max-height="125"
        >
        </multiselect>
        <vs-input v-else class="w-full bg-grey-light rounded-lg" v-model="currency.to.code" disabled></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('To Currency')"
        >{{ errors.first('To Currency') }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Exchange Rate</label>
        <vs-input
          :disabled="!editable"
          class="w-full"
          :class="{'bg-grey-light rounded-lg': !editable}"
          v-validate="'required'"
          name="exchange_rate"
          v-model="data.exchange_rate"
          @keypress="isNumber($event)"
          @change="changeRate"
        />
        <span class="text-danger text-sm" v-show="errors.has('exchange_rate')">{{
          errors.first("exchange_rate")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Valid From</label>
        <flat-pickr
          v-if="editable"
          :config="flatPickrConfig"
          v-model="data.valid_from"
          class="w-full"
        />
        <vs-input v-else class="w-full bg-grey-light rounded-lg" :value="formatDate(data.valid_from)" disabled></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('Valid From')"
        >{{ errors.first('Valid From') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Valid To</label>
        <flat-pickr
          v-if="editable"
          :config="flatPickrConfig"
          v-model="data.valid_to"
          class="w-full"
        />
        <vs-input v-else class="w-full bg-grey-light rounded-lg" :value="formatDate(data.valid_to)" disabled></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('Valid To')"
        >{{ errors.first('Valid To') }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment"
export default {
  components: {
    flatPickr
  },
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          rate: 0,
          exchange_rate: 0,
          valid_from: Date.now(),
          valid_to: Date.now(),
          currency_code: null,
        },
        editable: true,
        currency: {
          from: null,
          to: null,
        },
        options: [],
        flatPickrConfig: {
          maxDate: null,
          minDate: Date.now(),
          parseDate: (val) => moment.utc(val).toDate(),
          defaultDate: Date.now(),
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.from_currency) {
          this.errors.add({
            field: "From Currency",
            msg: "The From Currency field is required",
          });
          result = false;
        }
        if (!this.data.to_currency) {
          this.errors.add({
            field: "To Currency",
            msg: "The To Currency field is required",
          });
          result = false;
        }
        if (this.data.from_currency == this.data.to_currency) {
          this.errors.add({
            field: "From Currency",
            msg: "From currency and to currency must be different",
          });
          this.errors.add({
            field: "To Currency",
            msg: "From currency and to currency must be different",
          });
          result = false;
        }
        if (parseInt(this.data.exchange_rate) <= 0) {
          this.errors.add({
            field: "exchange_rate",
            msg: "Exchange Rate must be greater than 0",
          });
          result = false;
        }
        if (!this.data.valid_from) {
          this.errors.add({
            field: "Valid From",
            msg: "The Valid From field is required",
          });
          result = false;
        }
        if (!this.data.valid_to) {
          this.errors.add({
            field: "Valid To",
            msg: "The Valid To field is required",
          });
          result = false;
        }
        if (moment(this.data.valid_to).isBefore(moment(this.data.valid_from))) {
          this.errors.add({
            field: "Valid To",
            msg: "The Valid To field must greater than Valid From",
          });
          result = false;
        }
        if (result) {
          this.$vs.loading();
            if (this.id) {
              this.putData();
            } else {
              this.postData();
            }
        }
      });
    },
    acceptDefault(){
      this.$vs.loading();
      if (this.id) {
        this.putData();
      } else {
        this.postData();
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
       evt.preventDefault();
      } else {
        return true;
      }
    },
    formatNumber: function(val) {
      return Intl.NumberFormat("en", {minimumFractionDigits: 2}).format(val);
    },
    changeRate(e) {
      this.data.exchange_rate =  this.formatNumber(e.target.value.replaceAll(",", ""))
    },
    paramData() {
      return {
        from_currency: this.data.from_currency,
        to_currency: this.data.to_currency,
        exchange_rate: parseFloat(this.data.exchange_rate.toString().replaceAll(',', '')),
        date_exchange_rate: this.id
          ? moment.utc(this.data.date_exchange_rate).format("YYYY-MM-DD")
          : moment(this.data.date_exchange_rate).format("YYYY-MM-DD"),
        valid_from: this.id
          ? moment.utc(this.data.valid_from).format("YYYY-MM-DD")
          : moment(this.data.valid_from).format("YYYY-MM-DD"),
        valid_to: this.id
          ? moment.utc(this.data.valid_to).format("YYYY-MM-DD")
          : moment(this.data.valid_to).format("YYYY-MM-DD"),
        type: "tax-rate",
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
        this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.data.exchange_rate = this.formatNumber(resp.data.exchange_rate);
            this.data.valid_from = this.formatDate(resp.data.valid_from);
            this.data.valid_to = this.formatDate(resp.data.valid_to);
            this.data.from_currency = resp.data.from_currency_id;
            this.data.to_currency = resp.data.to_currency_id;
            this.currency.from = {id: resp.data.from_currency_id, code: resp.data.from_currency}
            this.currency.to = {id: resp.data.to_currency_id, code: resp.data.to_currency}
            this.editable = resp.data.editable
            this.data.currency_code = resp.data.currency_code
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getAllDataCurrency() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/currency", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.options = resp.data.records.map((c) => ({code: c.Code, id: c.ID}));
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
  },
  mounted() {
    this.getAllDataCurrency();
    if (this.id) {
      this.getData();
    }
  },
  computed: {
    formatDate: function () {
      return (val) => moment.utc(val).format("YYYY-MM-DD")
    }
  },
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
      this.getAllDataCurrency();
    },
    "currency.from": function (val) {
      if (val) {
        this.errors.clear();
        this.data.from_currency = val.id;
      } else {
        this.data.from_currency = null;
      }
    },
    "currency.to": function (val) {
      if (val) {
        this.errors.clear();
        this.data.to_currency = val.id;
      } else {
        this.data.to_currency = null;
      }
    },
    "currency.exchange_rate": function (val) {
      if (val) {
        this.errors.clear();
      }
    },
    "data.valid_from": function (val) {
      console.log("valid_from")
      if (val) {
        this.errors.clear();
      }
    },
    "data.valid_to": function (val) {
      console.log("valid_to")
      if (val) {
        this.errors.clear();
      }
    },
  },
};
</script>
<style >
</style>
